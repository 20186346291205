import { VideoStreamRenderer } from "@azure/communication-calling";
import MultiVisioDisplayManager from "./visio_display_manager";

export default class AcsVisioHelper {
  constructor(roomId) {
    this.visioDisplayManager = new MultiVisioDisplayManager();
    this.roomId = roomId;
  }

  async subscribeToCall(call) {
    for (const localVideoStream of call.localVideoStreams) {
      await this.addAcsVideoStream(localVideoStream);
    }

    call.on("localVideoStreamsUpdated", async (e) => {
      for (const localVideoStream of e.added) {
        await this.addAcsVideoStream(localVideoStream);
        if (localVideoStream.mediaStreamType === "ScreenSharing") {
          this.visioDisplayManager.toggleScreenShareButton(true);
        }
      }
      e.removed.forEach((localVideoStream) => {
        if (localVideoStream.mediaStreamType === "ScreenSharing") {
          this.visioDisplayManager.toggleScreenShareButton(false);
        }
      });
    });

    call.on("remoteParticipantsUpdated", async (e) => {
      // Subscribe to new remote participants that are added to the call.
      for (const remoteParticipant of e.added) {
        await this.subscribeToRemoteParticipant(remoteParticipant);
      }
    });

    call.on("isMutedChanged", () => { this.visioDisplayManager.toggleMuteMicrophoneButtonState() });
  }

  async subscribeToRemoteParticipant(remoteParticipant) {
    // subscribe to streams already present
    for (const videoStream of remoteParticipant.videoStreams) {
      await this.subscribeToRemoteVideoStream(videoStream);
    }
    remoteParticipant.on("videoStreamsUpdated", async (e) => {
      // subscribe to streams that are added later on
      for (const videoStream of e.added) { await this.subscribeToRemoteVideoStream(videoStream); }
    });
  }

  async subscribeToRemoteVideoStream(videoStream) {
    if (videoStream.isAvailable) {
      // Add remote participant initial video streams
      await this.addAcsVideoStream(videoStream);
    }
    // Subscribe to participant's video stream changes
    videoStream.on("isAvailableChanged", async () => {
      if (videoStream.isAvailable) {
        await this.addAcsVideoStream(videoStream);
      } else {
        this.visioDisplayManager.removeStream(videoStream);
      }
    });
  }

  // This function creates an HTML View of a given VideoStream, add the wanted style and asks the
  // visioDisplayManager to display it
  async addAcsVideoStream(videoStream) {
    console.log(videoStream)
    const videoStreamRenderer = new VideoStreamRenderer(videoStream);
    const streamView = await videoStreamRenderer.createView();
    streamView.target
      .getElementsByTagName("video")[0]
      .setAttribute("playsInline", "true");
    streamView.target
      .getElementsByTagName("video")[0]
      .setAttribute("style", "width: 100%; height: 100%; object-fit: fill;");

    const acsUserId = videoStream.kind === 'LocalVideoStream' ? 'myself' : videoStream.tsParticipantId
    this.visioDisplayManager.displayStream(videoStream.mediaStreamType, streamView.target, acsUserId);
  }
}
