// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
// import "@hotwired/turbo-rails"

import $ from 'jquery'
window.jQuery = $;
window.$ = $;

import * as Turbo from '@hotwired/turbo'
import "trix"
import "@rails/actiontext"
import Rails from "@rails/ujs"

import "@fortawesome/fontawesome-free/css/all.css"

import '~/stylesheets/application.scss'

import "@hotwired/turbo-rails"

import * as bootstrap from 'bootstrap'

import "chartkick/chart.js"

// previsously found in app/javascript/index.js
import { Application } from "stimulus"
import { registerControllers } from 'stimulus-vite-helpers'
const controllers = import.meta.glob(['../**/*_controller.js', '../../components/**/*_controller.js'], { eager: true })
import Autocomplete from "stimulus-autocomplete";
import NestedForm from 'stimulus-rails-nested-form';
import { Multiselect } from '@wizardhealth/stimulus-multiselect'
const application = Application.start();
application.register('autocomplete', Autocomplete);
application.register('nested-form', NestedForm);
application.register('multiselect', Multiselect)
registerControllers(application, controllers)

document.addEventListener("turbo:load", () => {
  // Enable tooltips
  [...document.querySelectorAll('[data-bs-toggle="tooltip"]')].forEach((node) => { new bootstrap.Tooltip(node, {trigger : 'hover'}); })

  // fire custom event when modal is closed, this is then proceed by our stimulus controller
  const myModal = document.getElementById('list360Modal')
  if (myModal) {
    myModal.addEventListener('hide.bs.modal', function (e) {
      const event = new CustomEvent('modal-will-close', { detail: { modalIdentifier: e.target, modalCloseEvent: e}});
      window.dispatchEvent(event);
    })
  }
})

Turbo.start()
// FIXME: Find a better way than checking window._rails_loaded to ensure
// consistent behavior between hosted envs, dev env with a vite server and
// dev env without a vite server.
//
// As of today, for an unknown reason, rails is already started and causes an
// error if you try to call Rails.start() here.
//
// Please note: This error is NOT reproducible on your dev env when a vite dev
// server is running.
if (!window._rails_loaded) {
  Rails.start()
}

// define a custom scroll_to turbo action
Turbo.StreamActions.scroll_to = function () {
  const target = this.targetElements[0];
  target.lastElementChild.scrollIntoView({behavior: "smooth"});
};
