import AcsVisioCallAdapter from '././one_on_one/acs_visio_call_adapter';
import AcsMultiVisioAdapter from "././multi/acs_visio_call_adapter";
import TestVisioCallAdapter from './test_visio_call_adapter';

export function getVisioCallAdapter(serviceIdentifier, oneOnOneVisio, acsAccessToken, roomId, testScenario) {
  switch (serviceIdentifier) {
    case 'acs':
      if (oneOnOneVisio !== true) {
        return new AcsMultiVisioAdapter(acsAccessToken, roomId)
      }
      return new AcsVisioCallAdapter(acsAccessToken, roomId)
    case 'test':
      return new TestVisioCallAdapter(roomId, testScenario);
    default:
      throw new Error('Unsupported visio call service');
  }
}
