/**
 * This Class handles every change into the HTML view of the visio.
 * It is supposed to be reusable by different visio adapters (ACS, ...)
 */
export default class MultiVisioDisplayManager {
  constructor() {
    this.initializeDOMElements();
  }

  initializeDOMElements() {
    this.screen_share_button = document.getElementById("toggleScreenShareButton");
    this.mute_microphone_button = document.getElementById("toggleMuteButton");
    this.blur_video_button = document.getElementById("toggleBlurButton");
    this.connection_in_progress_msg = document.getElementById("connectionInProgressMsg");
    this.connection_please_retry_msg = document.getElementById("connectionPleaseRetry");
    this.connection_disable_adblock = document.getElementById("connectionAdblockDetected");
    this.error_message = document.querySelector(".visio__error-msg");

    this.visio_container = document.getElementById("visio");
    this.user_video_container = document.getElementById("userVideoContainer");
    this.screensharing_container = document.getElementById("screensharingContainer");
  }

  displayStream(streamType, streamElement, userId) {
    if (streamType === "Video") {
      const newElement = Object.assign(document.createElement("div"), {
        className: "multi-visio__user-video",
        id: userId,
      });
      newElement.replaceChildren(streamElement);
      this.user_video_container.prepend(newElement);

      if (this.user_video_container.children.length === 1) {
        this.user_video_container.classList.add("multi-visio__user-list--full-width");
      } else {
        this.user_video_container.classList.remove("multi-visio__user-list--full-width");
      }
    } else if (streamType === "ScreenSharing") {
      this.visio_container.classList.add("multi-visio--with-screen-shared");
      this.screensharing_container.replaceChildren(streamElement);
    }
  }

  removeStream(streamType, userId = null) {
    if (streamType === "Video") {
      document.getElementById(userId).remove();
    } else if (streamType === "ScreenSharing") {
      this.visio_container.classList.remove("multi-visio--with-screen-shared");
      this.screensharing_container.replaceChildren("");
    }
  }

  enableActionButtons() {
    this.mute_microphone_button.disabled = false;
    this.screen_share_button.disabled = false;
    this.connection_in_progress_msg.classList.add("d-none");
    if (this.blur_video_button) {
      this.blur_video_button.disabled = false;
    }
  }

  toggleScreenShareButton(toActive) {
    if (toActive) {
      this.screen_share_button.classList.add("icon--active");
      this.visio_container.classList.add("multi-visio--with-screen-shared");
    } else {
      this.screen_share_button.classList.remove("icon--active");
      this.visio_container.classList.remove("multi-visio--with-screen-shared");
    }
  }

  toggleBlurVideoButton(toActive) {
    if (this.blur_video_button) {
      if (toActive) {
        this.blur_video_button.classList.add("icon--active");
      } else {
        this.blur_video_button.classList.remove("icon--active");
      }
    }
  }

  toggleMuteMicrophoneButtonState() {
    this.mute_microphone_button.classList.toggle("icon--inactive");
  }

  displayConnectionErrorMsg(potentialAdblock) {
    this.connection_in_progress_msg.classList.add("d-none");
    this.error_message.classList.remove("d-none");
    if (potentialAdblock) {
      this.connection_disable_adblock.classList.remove("d-none");
    } else {
      this.connection_please_retry_msg.classList.remove("d-none");
    }
  }
}
