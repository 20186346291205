/*
This is used by the feature tests (rspec) to test that the stimulus controller calls the adapter's functions
when the visio page buttons are clicked
 */
import VisioDisplayManager from "././one_on_one/visio_display_manager";

class TestVisioCallAdapter {
    constructor(roomId, testScenario) {
        this.testScenario = testScenario
        this.roomId = roomId
        this.visio_display_manager = new VisioDisplayManager()
        this.isBlurred = false
        this.isScreenShared = false
        this.isMicroMuted = false
        this.videoDevices = [
              { id: 'fake-video-device-id', name: 'fake-video' },
              { id: 'fake-video-device-id-1', name: 'fake-video-1' },
        ]
        this.activeVideoDevice = null
        this.audioInputDevices = [
            { id: 'fake-microphone-device-id', name: 'fake-microphone' },
            { id: 'fake-microphone-device-id-1', name: 'fake-microphone-1' },
        ]
        this.audioOuputDevices = [
          { id: 'fake-speaker-device-id', name: 'fake-audio' },
          { id: 'fake-speaker-device-id-1', name: 'fake-audio-1' },
        ]
    }

    async joinCall() {
        if (this.testScenario === 'wait_join_call' ) {
            await new Promise(() => {}); // this will never return
        }
        if (this.testScenario === 'fail_join_call' ) {
            return Promise.reject(new Error('Some error'))
        }
        if (this.testScenario === 'fail_join_call_timeout' ) {
            return Promise.reject(new Error('User stack init timeout. Error: Operation timed out'))
        }

        // else, 'success_join_call':
        this.videoNodeToInject = document.createElement('div')
        this.videoNodeToInject.innerHTML = `videostream for ${this.roomId}`
        this.visio_display_manager.displayStream('video', true, this.videoNodeToInject)

        return Promise.resolve()
    }

    leaveCall() {
        const nodeToInject = document.createElement('div')
        nodeToInject.innerHTML = 'hangedup'
        this.visio_display_manager.displayStream('video', true, nodeToInject)
    }

    toggleMuteMicrophone() {
        this.isMicroMuted = !this.isMicroMuted
        this.visio_display_manager.toggleMuteMicrophoneButtonState(this.isMicroMuted)
    }

    toggleScreenShare() {
        this.isScreenShared = !this.isScreenShared
        this.visio_display_manager.toggleScreenShareButton(this.isScreenShared)
    }

    toggleBlur() {
        this.isBlurred = !this.isBlurred
        return this.isBlurred
    }

    cleanOnDisconnect() {}

    async getAvailableOutputAudioDevices() {
      return Promise.resolve(this.audioOuputDevices)
    }

    async getAvailableInputAudioDevices() {
      return Promise.resolve(this.audioInputDevices)
    }

    async getAvailableVideoDevices() {
      return Promise.resolve(this.videoDevices)
    }


    async setOutputAudioDevice(_audioDeviceId) {
    }

    async setInputAudioDevice(_audioDeviceId) {
    }

    async setVideoDevice(videoDeviceId) {
      const videoDevice = this.videoDevices.find((device) => ( device.id == videoDeviceId))
      this.videoNodeToInject.innerHTML = `videostream for ${this.roomId} - device : ${videoDevice.name}`
    }

}

export default TestVisioCallAdapter
